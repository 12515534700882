<template>
  <v-container id="alerts" fluid tag="section">
    <v-card>
      <v-card-text>
        <base-subheading subheading="API Routes" />

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Methods</th>
                <th class="text-left">Uri</th>
                <th class="text-left">Middleware</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in routes" :key="item.name">
                <td>
                  <v-chip
                    v-for="(mt, i) in item.methods"
                    :key="'mt'+i"
                    class="mr-1"
                    @click="debugRoute(mt, item.uri)"
                  >{{mt}}</v-chip>
                </td>
                <td>{{ item.uri }}</td>
                <td>
                  <span v-for="(md, i) in item.middleware" :key="'md'+i" class="mr-1">{{md}}</span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

export default {
  data () {
    return {
      routes: [],
    }
  },

  created () {
    this.get('/api/debug/routes/').then(data => {
      this.routes = data
    })
  },

  methods: {
    debugRoute (method, uri) {
      this.$router.push('/debug/routes/' + method + '/' + encodeURIComponent(uri))
    },
  },
}
</script>
